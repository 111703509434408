<template>
  <PageCard pageName="All Users" :iconClass="['fa', 'fa-users', 'fa-lg']">
    <template slot="page_content">
      <div v-if="$apollo.loading">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </div>
      <div v-else>
        <div class="row justify-content-center">
          <div class="col-sm-3 text-left mb-3 mt-2">
            <b-input-group>
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-search"></i
                ></span>
              </div>
              <b-form-input
                type="text"
                v-model="filterText"
                @keydown.native.stop
                placeholder="Search"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="col-sm-9 text-right mb-3">
            <b-button
              size=""
              variant="primary"
              class="fw-btn mt-2 mx-3"
              :disabled="!this.roles.length"
              v-b-modal.bulkInviteUsers
            >
              Bulk Invite Users
            </b-button>
            <b-button
              size=""
              variant="primary"
              class="fw-btn mt-2 mx-3"
              :disabled="!this.roles.length"
              v-b-modal.inviteUser
            >
              Invite User
            </b-button>
          </div>
          <div class="col-sm-12">
            <b-table
              :items="listingUsers"
              :fields="tableHeaders"
              :filter="filterText"
              :filter-included-fields="filterOn"
              @filtered="afterFilter"
              :show-empty="true"
              :current-page="currentPage"
              :per-page="perPage"
              empty-text="No records found."
              responsive
              striped
              hover
              small
            >
              <template v-slot:cell(actions)="data">
                <div class="text-right px-3">
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-sm"
                    @click="reInviteUser(data.item)"
                    v-if="!data.item.confirmed_at && data.item.is_approved"
                  >
                    {{ reInviteText(data.item) }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-sm"
                    @click="verifyUser(data.item)"
                    v-if="verifiable(data.item)"
                  >
                    Set As Verified
                  </button>
                  <router-link
                    tag="button"
                    class="btn btn-primary btn-sm mx-2"
                    :to="{
                      name: 'SuperAdminEditUser',
                      params: { id: data.item.id }
                    }"
                  >
                    Edit
                  </router-link>
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    @click="checkIfClientAdminBeforeDel(data.item)"
                    v-b-modal.deleteConfirmation
                  >
                    Delete
                  </button>
                </div>
              </template>
              <template v-slot:cell(deleteUser)="data"> </template>
            </b-table>
            <b-row>
              <b-col md="6">
                <b-pagination
                  :total-rows="filteredUsers ? filteredUsers.length : totalRows"
                  :per-page="perPage"
                  v-model="currentPage"
                >
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <b-modal
        id="deleteConfirmation"
        ref="deleteConfirmation"
        title="Delete User"
        @ok="deleteUserData"
        @cancel="resetSelectedUser"
      >
        <div v-if="isLoading">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="3"></content-placeholders-text>
          </content-placeholders>
        </div>
        <div v-else>
          <div v-if="showCAList">
            <p>
              Please select an alternate Client Admin for the group.
            </p>
            <select
              class="form-control operand-select"
              v-model="altClientAdmin"
            >
              <option value="null" selected disabled> Select one</option>
              <option
                v-for="(ca, index) in caOptions"
                :key="index"
                :value="ca.id"
                >{{
                  ca.first_name + ' ' + ca.last_name + ' [' + ca.email + ']'
                }}</option
              >
            </select>
          </div>
          <p v-else-if="selectedUser && selectedUser.role == 'Client Admin'">
            No alternate Client Admin available. Please assign a new client
            admin and try Delete User again.
          </p>
          <p v-else>Are you sure, want to delete the user?</p>
        </div>
      </b-modal>

      <b-modal
        id="reInviteConfirmation"
        ref="reInviteConfirmation"
        title="ReInvite User"
        @ok="resendUserInvite"
      >
        <p>Are you sure, want to resend {{ resendText }} for this user?</p>
      </b-modal>
      <b-modal
        id="verifyConfirmation"
        ref="verifyConfirmation"
        title="Verify User"
        @ok="setUserAsVerified"
      >
        <p>Are you sure, want to verify the user?</p>
      </b-modal>
      <InviteUser
        :roles="roles"
        :groups="groups"
        :superAdmin="true"
        @userAdded="fetchUsers"
      ></InviteUser>
      <BulkInviteUsers
        :roles="roles"
        :groups="groups"
        :superAdmin="true"
        @userAdded="fetchUsers"
      ></BulkInviteUsers>
    </template>
  </PageCard>
</template>

<script>
// global
import Vue from 'vue'
import store from '@/store/store'
import router from '@/router/index'
// ui components
import PageCard from '@/modules/core/components/layouts/PageCard'
import InviteUser from '../../admin/components/InviteUser'
import BulkInviteUsers from '../../admin/components/BulkInviteUsers'
//import gql from 'graphql-tag'
// api
import usersAPI from '@/api/finapps/super_admin/users'

export default {
  name: 'AllUsers',
  components: {
    PageCard,
    InviteUser,
    BulkInviteUsers
  },
  beforeCreate() {
    let hasPermission = store.getters['Ability/manageSuperAdminBoard']
    if (!hasPermission) {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  created() {
    this.fetchAllUsers()
    this.fetchInitData()
  },
  data() {
    return {
      users: [],
      currentPage: 1,
      perPage: 20,
      selectedUser: null,
      roles: [],
      groups: [],
      allUsers: [],
      filterOn: ['name', 'email', 'company', 'phone', 'role'],
      filterText: null,
      filteredUsers: null,
      altClientAdmin: null,
      caOptions: [],
      isLoading: false
    }
  },
  computed: {
    resendText() {
      return this.selectedUser && this.selectedUser.createdBy
        ? 'invite'
        : 'confirmation'
    },
    listingUsers() {
      return this.allUsers.filter(user => !user.isDeleted)
    },
    totalRows() {
      return this.listingUsers.length
    },
    tableHeaders() {
      return [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'company', label: 'Company', sortable: true },
        { key: 'phone', label: 'Phone', sortable: true },
        { key: 'role', label: 'Role', sortable: true },
        {
          key: 'actions',
          label: 'Actions',
          sortable: false,
          class: 'text-center'
        }
      ]
    },
    showCAList() {
      return (
        this.selectedUser &&
        this.selectedUser.role == 'Client Admin' &&
        this.caOptions.length > 0
      )
    }
  },
  // apollo: {
  //   allUsers: {
  //     query: gql`
  //       {
  //         allUsers(orderBy: "id") {
  //           id
  //           firstName
  //           email
  //           company
  //           phone
  //           confirmedAt
  //           isApproved
  //           createdBy
  //           role {
  //             name
  //           }
  //         }
  //       }
  //     `,
  //     client:
  //       window.location.host === process.env.VUE_APP_ETHOS_URL_NAME
  //         ? 'ethosClient'
  //         : 'apolloClient',
  //     update(data) {
  //       let usersData = data.allUsers.filter(user => user.isApproved != false)
  //       //let usersData = data.allUsers
  //       usersData.forEach(user => {
  //         user.isDeleted = false
  //         user.role_name = user.role ? user.role.name : null
  //       })
  //       return usersData
  //     }
  //   }
  // },
  methods: {
    checkIfClientAdminBeforeDel(user) {
      this.setSelectedUser(user)
      if (this.selectedUser.role === 'Client Admin') {
        this.fetchCAOptions(user)
      }
      this.$refs.deleteConfirmation.show()
    },
    fetchCAOptions() {
      this.isLoading = true
      usersAPI.fetchCAOptions(this.selectedUser.id).then(res => {
        this.caOptions = res.users
        this.isLoading = false
      })
    },
    verifyUser(user) {
      this.selectedUser = user
      this.$bvModal.show('verifyConfirmation')
    },
    setUserAsVerified() {
      return usersAPI.verifyUser(this.selectedUser.id).then(() => {
        this.fetchAllUsers()
        Vue.toasted.show('User verified successfully', {
          icon: 'user-circle',
          type: 'success'
        })
      })
    },
    verifiable(user) {
      return user.confirmed_at && user.is_approved && !user.is_active
    },
    fetchAllUsers() {
      usersAPI.allUsersData().then(res => {
        this.allUsers = res.users.filter(user => user.is_approved != false)
        this.allUsers.forEach(user => {
          user.isDeleted = false
        })
      })
    },
    afterFilter: function(filteredItems) {
      this.filteredUsers = filteredItems
      this.currentPage = 1
    },
    reInviteText(user) {
      return user.createdBy ? 'Re-invite' : 'Resend Confirmation'
    },
    setSelectedUser(user) {
      this.selectedUser = user
    },
    resetSelectedUser() {
      this.selectedUser = null
      this.caOptions = []
      this.altClientAdmin = null
    },
    reInviteUser(user) {
      this.selectedUser = user
      this.$bvModal.show('reInviteConfirmation')
    },
    resendUserInvite() {
      return usersAPI.reInviteUser(this.selectedUser.id).then(() => {
        let msg = this.selectedUser.createdBy
          ? 'Invite email resent successfully.'
          : 'Confirmation email resent successfully.'
        Vue.toasted.show(msg, {
          icon: 'user-circle',
          type: 'success'
        })
      })
    },
    // approve(user) {
    //   return usersAPI.approveUser(user.id).then(() => {
    //     Vue.toasted.show('User approved successfully.', {
    //       icon: 'user-circle',
    //       type: 'success'
    //     })
    //     this.fetchUsers()
    //   })
    // },
    deleteUserData(evt) {
      if (
        this.selectedUser &&
        this.selectedUser.role == 'Client Admin' &&
        this.caOptions.length < 1
      ) {
        this.resetSelectedUser()
        this.$refs.deleteConfirmation.hide()
      } else {
        evt.preventDefault()
        this.$http
          .delete(
            `/api/super_admin/users/${this.selectedUser.id}?alt_client_admin_id=${this.altClientAdmin}`,
            {
              handleErrors: true
            }
          )
          .then(
            res => {
              this.setSelectedUser({})
              this.maskUserAsDeleted(res.data.user.id)
              this.$refs.deleteConfirmation.hide()
              this.fetchAllUsers()
              Vue.toasted.show('User deleted successfully', {
                icon: 'user-circle',
                type: 'success'
              })
            },
            () => {
              Vue.toasted.show(
                'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
                {
                  icon: 'chain-broken',
                  type: 'error'
                }
              )
            }
          )
      }
    },
    maskUserAsDeleted(userId) {
      let user = this.allUsers.find(user => user.id === userId.toString())
      if (user) user.isDeleted = true
    },
    fetchInitData() {
      this.$http
        .get('/api/group_users/new', {
          handleErrors: true,
          params: { super_admin: true }
        })
        .then(res => {
          this.roles = this.formatRoleData(res.data.roles)
          this.groups = this.formatGroupData(res.data.groups)
        })
    },
    formatRoleData(roles) {
      // roles.forEach(role => {
      //   role.alias = role.name === 'Admin' ? 'Super Admin' : role.name
      // })
      roles.sort((a, b) => b.name.length - a.name.length)
      return roles
    },
    formatGroupData(groups) {
      groups = groups.filter(grp => grp.group_type !== 'User Group')
      groups.forEach(group => {
        group.alias = group.name + ' [' + group.id + '] - ' + group.group_type
      })
      return groups
    },
    fetchUsers() {
      this.$apollo.queries.allUsers.refetch()
    }
  }
}
</script>

<style lang="scss" scoped></style>
